// React Basic and Bootstrap
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Badge, Col, Container, Media, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import classnames from "classnames"
//Import Icons
import FeatherIcon from "feather-icons-react"
//Import components
import Pricing from "../components/pricingBox"
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/SEO/seo"
import FaqHtmlSerializer from "../utils/FaqHtmlSerializer"
import { RichText } from "prismic-reactjs"
import { getTrialDays } from "../assets/javascript/affiliate"

const PagePricing = ({ data: { prismicFeatures, prismicFaq } }) => {

  const [activeTab, setActiveTab] = useState("1")

  const features = prismicFeatures.data.features

  const pricings1 = [{
    id: 1,
    price: "14,95",
    duration: "mois",
    plan: "monthly",
    features: features
  }]

  const pricings2 = [{
    id: 1,
    price: "9,95",
    duration: "mois",
    plan: "annual",
    features: features
  }]

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <Layout>
      <SEO
        title={"Offrez le meilleur logiciel de comptabilité à votre entreprise"}
        desc={"À partir de 9,95€/mois, Bizyness vous offre une multitude de fonctionnalités pour gérer simplement la comptabilité de votre entreprise. Testez gratuitement pendant 15 jours !"}
      />
      <Header
        title="Un tarif clair et transparent"
        isBig={true}
        subtitle="Un abonnement unique, sans frais cachés."
      />

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title mb-4 pb-2">
                <h2 className="title mb-4">
                  {getTrialDays()} jours gratuits pour essayer toutes les fonctionnalités
                </h2>
                <p className="text-muted para-desc mb-0 mx-auto">
                  Commencez avec{" "}
                  <span className="text-primary font-weight-bold">
                      Bizyness
                    </span>{" "}
                  gratuitement, sans engagement ni carte de crédit.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs="12" className="mt-4 pt-2">
              <div className="text-center">
                <Nav
                  pills
                  className="rounded-pill justify-content-center d-inline-block border py-1 px-2"
                >
                  <NavItem className="d-inline-block mr-1">
                    <NavLink
                      className={classnames(
                        { active: activeTab === "1" },
                        "px-3",
                        "rounded-pill",
                        "monthly"
                      )}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      Mensuel
                    </NavLink>
                  </NavItem>
                  <NavItem className="d-inline-block">
                    <NavLink
                      className={classnames(
                        { active: activeTab === "2" },
                        "px-3",
                        "rounded-pill",
                        "yearly"
                      )}
                      onClick={() => {
                        toggleTab("2")
                      }}
                    >
                      Annuel{" "}
                      <Badge color="success" pill>
                        Économisez 33%{" "}
                      </Badge>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col md="4" xs="12" className="mt-4 pt-2"/>
                    {/* pricing */}
                    <Pricing pricings={pricings1}/>
                    <Col md="4" xs="12" className="mt-4 pt-2"/>
                  </Row>
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col md="4" xs="12" className="mt-4 pt-2"/>
                    {/* pricing */}
                    <Pricing pricings={pricings2}/>
                    <Col md="4" xs="12" className="mt-4 pt-2"/>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section bg-light">
        <Container>
          <Row>

            {prismicFaq.data.items.map((faq, key) =>
              <Col md={6} xs="12" className={`${key > 1 ? "mt-4 pt-2" : ""}`}>
                <Media>
                  <i>
                    <FeatherIcon
                      icon="help-circle"
                      className="fea icon-ex-md text-primary mr-2 mt-1"
                    />
                  </i>

                  <Media body>
                    <h3 className="mt-0 question">
                      {faq.question}
                    </h3>
                    <RichText render={faq.answer.raw} htmlSerializer={FaqHtmlSerializer}/>
                  </Media>
                </Media>
              </Col>
            )}

          </Row>

          <Row className="row mt-5 pt-md-4 justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title">
                <h3 className="title mb-4">
                  Une question avant de commencer ?
                </h3>
                <p className="text-muted para-desc mx-auto">
                  Utilisez le formulaire de contact, le chat ou appelez le <a href="tel:0458101949"
                                                                              className="text-primary">04 58 10 19
                  49</a> pour obtenir les réponses à toutes vos questions.
                </p>
                <Link to="/contact" className="btn btn-primary mt-4">
                  <i className="mdi mdi-email"></i> Envoyer un message
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default PagePricing

export const pageQuery = graphql`
  query Pricing {
    prismicFeatures {
      data {
        features {
          title
        }
      }
    }
    prismicFaq {
      data {
        items {
          answer {
            raw
          }
          question
        }
      }
    }
  }
`
