import React, { useState } from "react"
import { Button, Card, CardBody, Col, Modal, ModalBody } from "reactstrap"
//Import Icons
import FeatherIcon from "feather-icons-react"
import PropTypes from "prop-types"
import SignupForm from "../components/signupForm"

const Pricing = ({ pricings }) => {

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      {pricings.map((pricing, key) => (
        <Col name="pricing" md="4" xs="12" key={key} className="mt-4 pt-2">
          <Card
            name="pricingbox"
            className={
              pricing.isActive
                ? "pricing-rates business-rate shadow bg-white border-0 text-center rounded"
                : "pricing-rates business-rate shadow bg-light border-0 text-center rounded"
            }
          >
            <CardBody className="py-5">
              <div
                className="d-flex justify-content-center mb-4"
                name="pricingsection"
              >
                <span className="h4 mb-0 mt-2">€</span>
                <span className="price h1 mb-0">{pricing.price}</span>
                <span className="h4 align-self-end mb-1">
                  /{pricing.duration}
                </span>
              </div>

              <ul className="feature list-unstyled pl-0">
                {pricing.features.map((feature, key) => (
                  <li key={key} className="feature-list text-muted">
                    <i>
                      <FeatherIcon
                        icon="check"
                        className="fea icon-sm text-success mr-2"
                      />
                    </i>
                    {feature.title}
                  </li>
                ))}
              </ul>
              <Button color="primary" onClick={toggle}>
                Commencez gratuitement
              </Button>
              <Modal
                isOpen={modal}
                toggle={toggle}
                centered
              >
                <ModalBody>
                  <h4 className="text-center mb-3">Créez votre compte gratuitement</h4>
                  <SignupForm id={"pricing"} planId={pricing.plan} signin={false} variantId={1}/>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      ))}
    </>
  )
}

Pricing.propTypes = {
  pricings: PropTypes.array.isRequired
}

export default Pricing
