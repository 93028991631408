import React from "react"
import { Elements } from "prismic-reactjs"
import { linkResolver } from "./LinkResolver"
import { Link } from "gatsby"


const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

export const ArticlesHtmlSerializer = (type, element, content, children, key) => {


  var props = {}
  switch (type) {

    case Elements.paragraph:
      props = { className: "answer text-muted mb-0" }
      return React.createElement("p", propsWithUniqueKey(props, key), children)

    case Elements.hyperlink:
      const targetAttr = element.data.target ? { target: element.data.target } : {}
      const relAttr = element.data.target ? { rel: "noopener" } : {}
      props = Object.assign({
        className: "text-primary",
        href: element.data.url || linkResolver(element.data)
      }, targetAttr, relAttr)
      if (element.data.link_type === "Document") {
        return <Link to={linkResolver(element.data)} key={element.data.id} className="text-primary">
          {content}
        </Link>
      } else {
        return React.createElement("a", propsWithUniqueKey(props, key), children)
      }

    case Elements.list: // Unordered List
      props = Object.assign({
        className: "list-unstyled feature-list text-muted"
      })
      return React.createElement("ul", propsWithUniqueKey(props, key), children)


    case Elements.listItem: // Unordered List Item
      return <li>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             className="feather feather-check-circle fea icon-sm text-success mr-2">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        {children}</li>
      //return React.createElement('li', propsWithUniqueKey(props, key), children);


    // Return null to stick with the default behavior
    default:
      return null
  }

}

export default ArticlesHtmlSerializer